<template>
  <div>
    <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    <sub-head v-bind:subTitle="content['해피테이블 소개']"></sub-head>
    <div>
      <div class="page_padding">
        <div class="p-relative paddingT text-center">
          <span class="fontBold font41 line-height3 word_break title text333"
            >시니어 여가활동 증진 및 인지능력 향상 목적인 기능성 게임이 탑재된
            <span class="fontBold textPink">스마트 터치 테이블</span>
          </span>
        </div>
        <div class="verticalSpacer-half"></div>
        <div class="text-center">
          <span class="font18 word_break"
            >사용자 데이터 기반으로 경도인지 장애를 선별하고, 지속적인
            추적관리가 가능한 시스템
          </span>
        </div>
      </div>
      <div class="verticalSpacer-big"></div>
      <div class="page_padding p-relative product_picture">
        <div
          style="position:absolute; bottom:0; left:20%; right:20%; width:60%"
        >
          <div class="width100">
            <img src="../../assets/source/images/happytable_product1.png" />
          </div>
          <div class="d-flex justify-content-between block">
            <product-modal
              v-show="showModal"
              v-on:close="closeModal"
              :item="modalInfo"
            >
              <template>
                <div slot="body">
                  <div class="modal_img" style="border-radius:0px">
                    <img :src="getImgSrc(modalInfo.name, 'modal_product')" />
                    <!-- <image-component
                    :is_jpg="modalInfo.is_jpg"
                    path=""
                    :name="modalInfo.name"
                    :class="{modal_imgWrap: true}"
                  ></image-component> -->
                  </div>
                </div>
              </template>
            </product-modal>
            <div
              v-for="(product, index) in 3"
              :key="index"
              class="main_product_overlay p-relative"
              @mouseover="is_mouseOver(index)"
              @mouseout="is_mouseDown(index)"
              :class="[
                {is_on: is_over && pic_index == index},
                {['pic' + (index + 2)]: true},
              ]"
              v-on:click="product_detail(index)"
            >
              <div class="main_img">
                <img :src="getImgSrc(index, '')" />
              </div>
              <div class="overlay_icon_position">
                <div class="overlay_flex">
                  <div class="overlay_wrap">
                    <img src="../../assets/source/images/ic_bigimg.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="page_padding">
        <div class="paddingTB">
          <div class="text-center">
            <span class="font41 fontBold text333 title"
              >최고의 인지능력 향상 프로그램으로
              <span class="textPink fontBold">치매 예방에도 기여</span
              >합니다.</span
            >
          </div>
          <div class="verticalSpacer-half"></div>
          <div class="happytable">
            <div
              v-for="(item, index) in ht_info"
              :key="index"
              :class="[
                {'d-flex': index % 2 == 0},
                {'d-flex-reverse': index % 2 == 1},
              ]"
              class="text-left margin_3"
            >
              <div class="width60 info_img">
                <image-component
                  :is_jpg="true"
                  :name="item.ht_imgSrc"
                  path=""
                ></image-component>
              </div>
              <div class="width40 right_text">
                <!-- <div class="content-padding"> -->
                <div class="">
                  <span class="font38 fontLight text333">{{
                    item.ht_headline
                  }}</span>
                </div>
                <div class="verticalSpacer-half"></div>
                <div>
                  <span class="fontS" v-html="item.ht_content"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_padding bgBackground">
        <div class="paddingTB">
          <div>
            <span class="font41 fontBold word_break text333 title"
              >기능성 게임 활동 및
              <span class="textPink fontBold">데이터 및 관리</span></span
            >
          </div>
          <div class="verticalSpacer"></div>
          <div class="verticalSpacer"></div>
          <div class="cms d-flex">
            <div class="width60 right_text">
              <div class="text-left">
                <div>
                  <span class="font34 fontLight line-height4 word_break text333"
                    >해피 테이블을 통해 진행하는 모든 인지 활동 프로그램에 대한
                    데이터를 수집합니다.</span
                  >
                </div>
                <div class="paddingTB1">
                  <span
                    >이러한 데이터는 해피 테이블만의 알고리즘을 통하여 반응력,
                    기억력, 문제 해결력, 주의력, 유연성, 수리력에 대한 인지 기능
                    측정 및 치매에 관련한 위험도를 분석하고 경고합니다.</span
                  >
                </div>
              </div>
            </div>
            <div class="width40 imgWrap">
              <img
                class="borderRadius"
                src="../../assets/source/images/happytable_info5.jpg"
              />
            </div>
          </div>
          <div class="verticalSpacer"></div>
          <div class="verticalSpacer"></div>
          <div class="cms_content">
            <div
              class="d-flex content_index"
              style="padding: 1rem 0"
              v-for="(item, index) in cms_info"
              :key="index"
            >
              <div
                class="iconWrap flex-set"
                style="background-color:white; border-radius:50%;"
              >
                <img :src="getCMSImgSrc(item.cms_imgSrc)" />
              </div>
              <div class="text-left word_break paddingM width70">
                <img :src="getCMSImgSrc(item.index_imgSrc)" />
                <br />
                <span class="font18 fontExtraBold">{{ item.cms_headline }}</span
                ><br />
                <span class="fontS">{{ item.cms_content }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="show_game()" class="paddingT">
        <div>
          <div>
            <span class="font41 fontBold text333"
              >해피테이블 <span class="textPink fontBold">체험하기</span></span
            >
          </div>
          <div class="verticalSpacer-half"></div>
          <div>
            <span class="font18"
              >마우스로 클릭하여 해피테이블 게임을 체험해보세요.</span
            >
          </div>
        </div>
        <div class="verticalSpacer"></div>
        <div class="">
          <div class="edge p-positive">
            <div class="p-relative" style="padding: 0 25%;">
              <div class="wrapper">
                <div class="iframe-wrapper" style="">
                  <div class="experience_logo">
                    <img src="../../assets/source/images/experience_logo.png" />
                  </div>
                  <div class="iframe">
                    <iframe
                      src="http://springsoft.io/test/webgame/balloonpop/"
                      allowfullscreen="true"
                      :width="frameWidth"
                      :height="frameHeight"
                      scrolling="no"
                      noresize="noresize"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="verticalSpacer"></div>
            <div class="verticalSpacer"></div>
            <div class="page_padding d-flex justify-content-between game_more">
              <div class="marginTBAuto marginAuto">
                <span class="font30 textWhite"
                  >해피테이블의 더 다양한
                  <span class="fontBold">게임을 확인해보세요.</span></span
                >
              </div>
              <div class="marginTBAuto">
                <router-link to="/game" class="main_btn">
                  <div class="btn marginAuto">
                    <span class="fontLight fontS">게임 보러 가기</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import cmsContents from "../../assets/source/json/happytable_cms.json";
import htContents from "../../assets/source/json/happytable_info.json";
import subHead from "../../components/sub_head.vue";
import ProductModal from "../../components/modal/product_modal.vue";
import ImageComponent from "../../components/imageComponent.vue";
export default {
  components: {subHead, ProductModal, ImageComponent},
  data() {
    return {
      content: jsonContents,
      ht_info: htContents,
      cms_info: cmsContents,
      is_over: false,
      pic_index: 0,
      showModal: false,
      modalInfo: {
        is_jpg: true,
        name: "happytable_product2",
        path: "",
      },
      frameWidth: "700",
      frameHeight: "395",
      showGame: true,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getImgSrc(src, path) {
      var images;
      if (path == "") {
        src = "happytable_product" + (src + 2);
        images = require.context(
          "../../assets/source/images/",
          false,
          /\.jpg$/
        );
        return images("./" + src + ".jpg");
      } else if (path == "modal_product") {
        images = require.context(
          "../../assets/source/images/",
          false,
          /\.jpg$/
        );
        return images("./" + src + ".jpg");
      } else {
        images = require.context(
          "../../assets/source/images/screenshots",
          false,
          /\.jpg$/
        );
        return images("./" + src + ".jpg");
      }
    },
    getCMSImgSrc(src) {
      var images = require.context(
        "../../assets/source/images",
        false,
        /\.png$/
      );
      return images("./" + src + ".png");
    },
    is_mouseOver(index) {
      this.pic_index = index;
      this.is_over = true;
    },
    is_mouseDown(index) {
      this.pic_index = index;
      this.is_over = false;
    },
    product_detail(index) {
      this.showModal = true;
      this.modalInfo.name = "happytable_product" + (index + 2);
      document.querySelector("body").style.overflow = "hidden";
      return this.modalInfo;
    },

    closeModal() {
      this.showModal = false;
      document.querySelector("body").style.overflowY = "scroll";
    },
    handleResize() {
      if (matchMedia("(min-width: 1701px)").matches) {
        this.frameWidth = "700";
        this.frameHeight = "395";
      } else if (
        matchMedia("(min-width: 1024px) and (max-width: 1279px)").matches
      ) {
        this.frameWidth = "380";
        this.frameHeight = "220";
      } else if (
        matchMedia("(min-width: 768px) and (max-width: 919px)").matches
      ) {
        this.frameWidth = "250";
        this.frameHeight = "150";
      } else if (
        matchMedia("(min-width: 920px) and (max-width: 1023px)").matches
      ) {
        this.frameWidth = "320";
        this.frameHeight = "190";
      } else if (
        matchMedia("(min-width:1281px) and (max-width:1500px)").matches
      ) {
        this.frameWidth = "500";
        this.frameHeight = "300";
      } else if (
        matchMedia("(min-width:1501px) and (max-width:1700px)").matches
      ) {
        this.frameWidth = "600";
        this.frameHeight = "350";
      }
      if (matchMedia("(max-width:767px)").matches) {
        this.showGame = false;
      } else {
        this.showGame = true;
      }
    },
    show_game() {
      navigator.sayswho = (function() {
        var ua = navigator.userAgent,
          tem,
          M =
            ua.match(
              /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return "IE " + (tem[1] || "");
        }
        if (M[1] === "Chrome") {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
          if (tem != null)
            return tem
              .slice(1)
              .join(" ")
              .replace("OPR", "Opera");
        }
        M = M[2]
          ? [M[1], M[2]]
          : [navigator.appName, navigator.appVersion, "-?"];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(" ");
      })();

      if (
        navigator.sayswho == "IE 11" ||
        navigator.sayswho == "MSIE 10" ||
        navigator.sayswho == "MSIE 9" ||
        matchMedia("(max-width: 767px)").matches
      ) {
        this.showGame = false;
      }
      return this.showGame;
    },
  },
};
</script>

<style scoped>
.marginTBAuto {
  margin: auto 0;
}
.paddingT {
  padding-top: 100px;
}
.block div.main_product_overlay {
  margin: 1rem;
}
.block div.main_product_overlay.pic2 {
  margin-left: 0;
}
.block div.main_product_overlay:last-child {
  margin-right: 0;
}
.block div img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.block .modal_img img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.d-flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.d-flex .right_text {
  padding: 0 3rem;
}
.d-flex-reverse .right_text {
  padding-right: 1rem;
}
.iconWrap {
  width: 150px;
  height: 150px;
}
.iconWrap img {
  width: 50%;
  height: 50%;
}

.cms_content {
  display: flex;
  flex-wrap: wrap;
}
.cms_content .d-flex {
  width: 50%;
}
.happytable_game {
  background-image: url("../../assets/source/images/happytable.png");
  background-repeat: no-repeat;
  padding: 1rem;
}
.width100 img {
  width: 100%;
  border-radius: 10px;
}
.product_picture {
  background-image: url("../../assets/source/images/happytable_bg.jpg");
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 480px; */
  height: 25vw;
}
.overlay_wrap {
  width: 50px;
  height: 50px;
}
.overlay_icon_position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay_icon_position .overlay_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.overlay_wrap {
  z-index: -1;
}

.is_on .overlay_wrap {
  z-index: 10;
}
.imgWrap img {
  width: 100%;
  height: 100%;
}
.edge {
  position: relative;
}

.iframe-wrapper {
  position: relative;
  background-color: #2b2e35;
  border-radius: 40px;
  padding: 7rem 0;
  width: 100%;
}
.iframe-wrapper .iframe {
  /* position: absolute; */
  width: 100%;
  height: 100%;
}

.imgWrap img {
  width: 100%;
  border-radius: 10px;
}
.experience_logo {
  position: absolute;
  bottom: 40px;
  left: 40px;
  /* width: 80px;
  height: 80px; */
}
.verticalSpacer-big {
  width: 100%;
  height: 10vw;
  margin: 0 auto;
}
.content-padding {
  padding: 3rem 0;
}
.margin_3 {
  margin: 3rem 0;
  padding: 2rem 0;
}
.info_img .imgWrap > img {
  border-radius: 10px !important;
}
.modal_img {
  height: 100%;
}
.modal_img.imgWrap img {
  border-radius: 0px;
  height: 100% !important;
}
.modal_imgWrap.imgWrap {
  height: 100%;
}
.modal_imgWrap.imgWrap img {
  height: 100%;
}
iframe {
  width: 700;
  height: 395;
}

.btn:hover {
  background-color: transparent;
  border-color: white;
}
a {
  text-decoration: none;
}
.game_more {
  height: 180px;
  background-image: url("../../assets/source/images/gamemore_bg.jpg");
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .paddingT {
    padding-top: 80px;
  }
  /* .product_picture {
    height: 300px;
  } */
  .d-flex .right_text {
    padding: 0 1rem;
  }
  .content-padding {
    padding: 1rem 0;
  }
  .margin_3 {
    margin: 2rem 0;
    padding: 2rem 0;
  }
  iframe {
    width: 200;
    height: 150;
  }
  .game_more {
    height: 150px;
  }
  .iconWrap {
    width: 120px;
    height: 120px;
  }
  .block div.main_product_overlay {
    margin: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .paddingT {
    padding-top: 60px;
  }
  .content-padding {
    padding: 0.5rem 0;
  }
  .d-flex .right_text {
    padding: 0 1rem;
  }
  .iconWrap {
    width: 120px;
    height: 120px;
  }
  .cms_content {
    flex-direction: column;
  }
  .cms_content .d-flex {
    width: 100%;
  }
  .content_index {
    align-items: center;
  }
  .iframe-wrapper {
    padding: 5rem 0;
  }
  .experience_logo {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 50px;
    height: 50px;
  }

  .experience_logo img {
    width: 100%;
  }
  .block div.main_product_overlay {
    margin: 0.5rem;
  }
  .game_more {
    height: 130px;
  }
  .game_more .btn .fontS {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .paddingT {
    padding-top: 60px;
  }
  .font41 {
    font-size: 30px;
  }

  .happytable .d-flex,
  .happytable .d-flex-reverse {
    flex-direction: column;
  }
  .happytable .width60,
  .happytable .width40 {
    width: 100%;
    height: 100%;
  }
  .margin_3 {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
  .block div.main_product_overlay {
    margin: 0.2rem;
  }
  .block div.main_product_overlay.pic2 {
    margin-left: 0;
  }
  .block div.main_product_overlay:last-child {
    margin-right: 0;
  }
  .d-flex .right_text {
    padding: 0;
  }
  .content-padding {
    padding: 1.5rem 0;
  }
  .cms.d-flex {
    flex-direction: column;
  }
  .cms .width40,
  .cms .width60 {
    width: 100%;
  }
  .cms_content {
    flex-direction: column;
  }
  .iconWrap {
    width: 120px;
    height: 120px;
  }

  .cms_content .d-flex {
    width: 100%;
  }
  .content_index {
    align-items: center;
  }
  .title.font41 {
    font-size: 25px;
  }
  .title.line-height3 {
    line-height: 2.5rem;
  }
}
</style>
